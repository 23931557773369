import React, { FC } from 'react';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';

import ShopMessage from 'components/ShopMessage';
import VideoPlayer from 'components/VideoBlock/VideoPlayer';
import Faq from 'components/Faq';
import RecommendedProducts from 'components/RecommendedProducts';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';
import BodyRenderer from 'components/common/BodyRenderer';
import useScreenRecognition from 'hooks/useScreenRecognition';
import ShopButton from 'components/ShopButton';

import { SymptomSectionProps } from './model';

import './SymptomSection.scss';
import './SymptomSectionOverride.scss';

const elements = {
  'List block': ({ properties }, keyId) => (
    <DangerouslySetInnerHtml
      key={keyId}
      className={classnames('gs-symptom-section__panel-block', {
        [`bg-color-${properties?.color}`]: properties?.color,
        [`gs-symptom-section__panel-block--${properties?.blockTheme}`]: properties?.blockTheme,
      })}
      html={properties?.text}
    />
  ),
  'Recommended Products': ({ properties }, keyId) => {
    return (
      <RecommendedProducts
        key={keyId}
        {...properties}
        bgColor={properties?.color}
        productLinks={properties?.list}
      />
    );
  },
  'FAQ block': ({ properties }, keyId) => <Faq key={keyId} {...properties} />,
  'Video Block': ({ properties }, keyId) => (
    <div key={keyId} className="gs-symptom-section__panel-block">
      <VideoPlayer
        onlyVideo
        {...properties}
        preTitle={properties?.videoSettings?.preTitle}
        timeNames={{
          sec: properties?.videoSettings?.secText,
          min: properties?.videoSettings?.minText,
        }}
      />
    </div>
  ),
  'Shop Message Symptom': ({ properties }, keyId) => (
    <div key={keyId} className="gs-symptom-section__panel-block">
      <ShopMessage
        {...properties}
        blocks={properties?.icons}
        linkText={properties?.linkToShop?.[0].name}
        linkUrl={properties?.linkToShop?.[0].url}
        target={properties?.linkToShop?.[0].target}
      />
    </div>
  ),
  'Shop Button': ({ properties }, keyId) => (
    <div key={keyId} className="gs-symptom-section__panel-block">
      <ShopButton {...properties} />
    </div>
  ),
};

const SymptomSection: FC<SymptomSectionProps> = ({
  title,
  rteTitle,
  description,
  image,
  imageAlt,
  mobileImage,
  imageAlign,
  products,
  mobileImageAlt,
  panelBlocks,
  color,
  videoSettings,
  isArticlePage,
  sectionTheme,
}) => {
  const { isNotMobile } = useScreenRecognition();
  const bgColor = color?.length ? color[0] : null;
  const align = imageAlign?.length ? imageAlign[0] : null;
  const isDesktopImageExists = isNotMobile ? image?.fallbackUrl : true;

  const cx = classnames('gs-symptom-section', {
    [`gs-symptom-section--bg-${bgColor}`]: bgColor,
    [`gs-symptom-section--image-${align}`]: align,
    [`gs-symptom-section--${sectionTheme}`]: sectionTheme,
  });
  const introCssClassNames = classnames('gs-symptom-section__intro', {
    'gs-symptom-section__intro--no-image': !isDesktopImageExists,
  });

  return (
    <div className={cx}>
      <Container fluid>
        {title || description ? (
          <div className={introCssClassNames}>
            <div className="gs-symptom-section__intro-text">
              {title ? <h2 className="gs-symptom-section__intro-title">{title}</h2> : null}
              <DangerouslySetInnerHtml
                className="gs-symptom-section__intro-title"
                html={rteTitle}
              />
              <DangerouslySetInnerHtml
                className="gs-symptom-section__intro-description"
                html={description}
              />
            </div>
            <div className="gs-symptom-section__intro-image">
              {isNotMobile ? (
                image?.fallbackUrl ? (
                  <GatsbyImage
                    className="gs-symptom-section__intro-img"
                    fluid={image}
                    alt={imageAlt}
                  />
                ) : null
              ) : mobileImage?.fallbackUrl ? (
                <GatsbyImage
                  className="gs-symptom-section__intro-img gs-symptom-section__intro-img--mobile"
                  fluid={mobileImage}
                  alt={mobileImageAlt}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="gs-symptom-section__panels">
          <BodyRenderer
            bodyData={panelBlocks}
            bodyStructure={elements}
            bodyItemProps={{ ...{ videoSettings, products, isArticlePage } }}
          />
        </div>
      </Container>
    </div>
  );
};

export default SymptomSection;
